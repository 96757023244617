



























































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import { AppModule } from '@/store/modules/app'
import { updateCampaign } from '@/api/consoleApi/campaigns'

@Component({
    name: 'VsSplitTestOptions',
    components: {
        VsLoader,
        VsCollapseCard,
        VsInlineErrorsCheck,
        VsSeparator,
        VsHintCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) splitTest!: any
    @Prop({ required: true, default: '' }) campaignId!: any
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean
    private loading = false
    private tempWinner = 'Most Unique Clicks' // Highest Open Rate, Most Unique Clicks
    private tempTestSize = 20
    private tempTestDuration = 28800
    $refs: any

    openTab () {
        this.$emit('open-tab', 'splittestoptions')
    }

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get universityUrl () {
        return AppModule.universityUrl
    }

    get hoursDuration () {
        return this.tempTestDuration / 60 / 60
    }

    set hoursDuration (val) {
        this.tempTestDuration = val * 60 * 60
    }

    get hours () {
        if (!this.splitTest?.test_duration) return 1
        return this.splitTest.test_duration / 60 / 60
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get splitTestOptionsCardStatus () {
        return 'success'
    }

    get totalHintStatus () {
        return this.testSizeHint === 'success' && this.testDurationHint === 'success' && this.winnerHint === 'success' ? 'success' : 'warning'
    }

    get testSizeHint () {
        return this.tempTestSize >= 20 && this.tempTestSize <= 50 ? 'success' : 'warning'
    }

    get testDurationHint () {
        return this.hoursDuration > 24 ? 'warning' : 'success'
    }

    get winnerHint () {
        return this.tempWinner === 'Highest Open Rate' ? 'warning' : 'success'
    }

    private openTabSplitTestOptions () {
        this.tempWinner = this.splitTest.winner
        this.tempTestSize = this.splitTest.test_size || 20
        this.tempTestDuration = this.splitTest.test_duration || 3600
        this.openTab()
    }

    private async saveSplitTestOptions () {
        this.loading = true
        try {
            let test_duration = this.tempTestDuration
            if (test_duration > 259200) test_duration = 259200
            if (test_duration < 3600) test_duration = 3600

            await updateCampaign(
                this.campaignId,
                {
                    split_test: {
                        winner: this.tempWinner,
                        test_size: this.tempTestSize,
                        test_duration,
                    },
                },
            )
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Impostazioni salvate con successo',
                aspect: VsToastAspectEnum.success,
            })
            this.$emit('split-test-options-saved')
            this.$emit('open-tab', '')
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante il salvataggio delle impostazioni',
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }
}
