













































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import CampaignNameModal from '@/modules/campaigns/components/CampaignNameModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import moment from 'moment'
import {
    abortCampaign,
    deleteCampaign,
    duplicateCampaign,
    getCampaign,
    pauseCampaign,
    scheduleCampaign,
    updateCampaign,
} from '@/api/consoleApi/campaigns'
import VsEmailSenderCard from '@/modules/campaigns/components/VsEmailSenderCard/Index.vue'
import VsSmsSenderCard from '@/modules/campaigns/components/VsSmsSenderCard/Index.vue'
import VsEmailSubjectCard from '@/modules/campaigns/components/VsEmailSubjectCard/Index.vue'
import VsEmailTargetCard from '@/modules/campaigns/components/VsEmailTargetCard/Index.vue'
import VsEmailContentCard from '@/modules/campaigns/components/VsEmailContentCard/Index.vue'
import VsEmailAdvancedCard from '@/modules/campaigns/components/VsEmailAdvancedCard/Index.vue'
import VsCampaignStatusCard from '@/modules/campaigns/components/VsCampaignStatusCard/Index.vue'
import VsSmsContentCard from '@/modules/campaigns/components/VsSmsContentCard/Index.vue'
import VsPhonePreview from '@/modules/campaigns/components/VsPhonePreview/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import { get } from 'lodash'
import {
    ICampaign,
    ScheduleCampaignDto,
    CampaignStatusEnum,
} from '@/api/consoleApi/dto/campaigns.dto'
import { AppModule } from '@/store/modules/app'
import { SmsCounter } from '@/utils/smsCounter'
import axios from 'axios'
import { getEmailTags, uploadThumbnailImage } from '@/api/consoleApi/contents/emails'
import { UserModule } from '@/store/modules/user'
import VsTabsHeading from '@/components/VsTabsHeading/Index.vue'
import VsSplitTestEmailContentCard from '@/modules/campaigns/components/VsSplitTestEmailContentCard/Index.vue'
import VsSplitTestOptionsCard from '@/modules/campaigns/components/VsSplitTestOptionsCard/Index.vue'
import html2canvas from 'html2canvas'
import { processImage } from '@/utils/screenshot'

@Component({
    name: 'VsCampaignEdit',
    components: {
        VsSidebarLayout,
        VsContainer,
        VsSectionHeader,
        VsLoader,
        CampaignNameModal,
        VsConfirm,
        VsEmailSenderCard,
        VsTabsHeading,
        VsEmailTargetCard,
        VsEmailSubjectCard,
        VsEmailContentCard,
        VsEmailAdvancedCard,
        VsCampaignStatusCard,
        VsSmsContentCard,
        VsSmsSenderCard,
        VsDropdownButton,
        VsSplitTestEmailContentCard,
        VsSplitTestOptionsCard,
        VsPhonePreview,
        VsSeparator,
    },
})
export default class extends Vue {
    private loading = false
    private showPianificationError = false
    private tabOpen = ''
    private screenShotUrl = ''
    private smsPreview = ''
    private smsCounter = new SmsCounter()

    private schedule = {
        now: false,
        date: new Date(),
    }

    private showScheduleCampaignModal = false
    private contentError = false
    private contactNumber = 3000
    private loadingCalculateContact = false
    private calendarOpen = false
    private pauseError = ''
    private percentageState = 0
    private showNotification = true
    private campaign: ICampaign | null = null

    private firstSelectedListCustomFields = []
    private callLoop: any = undefined
    selectedLists: any[] = []
    mappedTags: any[] = []
    $refs: any

    get tabs () {
        return [
            {
                label: 'Riepilogo',
                id: 'campaignEdit',
                to: {
                    name: 'campaignEdit',
                },
            },
            {
                label: 'Statistiche',
                id: 'campaignStatistics',
                disabled: this.campaign?.status === 'Draft',
                to: {
                    name: 'campaignStatistics',
                },
            },
        ]
    }

    get activeTab () {
        return this.$route.name
    }

    openTab (tabName: string) {
        this.tabOpen = tabName
    }

    get user () {
        return UserModule.user
    }

    get isAbSplit () {
        return this.campaign?.content_type === 'split_test'
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get isEditable () {
        if (!this.campaign) return false
        return (this.campaign.status === CampaignStatusEnum.Draft || (this.campaign.status === CampaignStatusEnum.Paused && this.campaign.status_reason !== 'AbSplitWaitingForWinner'))
    }

    get isReadyAndNeverSent () {
        if (!this.campaign) return false
        return ((this.campaign.status === CampaignStatusEnum.Ready || this.campaign.status === CampaignStatusEnum['Pending Approval']) && this.campaign.sent_start === '0000-00-00 00:00:00')
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get messageId () {
        return this.campaign?.message?.data?.id || ''
    }

    get isCampaignScheduled () {
        if (!this.campaign) return false
        return this.campaign.schedule_type !== 'Not Scheduled'
    }

    get previewCounter () {
        if (!this.smsPreview) return false
        return this.smsCounter.count(this.smsPreview)
    }

    get campaignStatusColor () {
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'info'
            case CampaignStatusEnum.Sending:
                return 'primary'
            case CampaignStatusEnum.Sent:
                return 'success'
            case CampaignStatusEnum.Failed:
                return 'alert'
            case CampaignStatusEnum.Paused:
                return 'warning'
            case CampaignStatusEnum['Pending Approval']:
            case CampaignStatusEnum.Draft:
            default:
                return 'grey'
        }
    }

    get campaignStatusIcon () {
        switch (this.campaign?.status || null) {
            case CampaignStatusEnum.Ready:
                return 'clock'
            case CampaignStatusEnum.Sending:
                return 'outgoing'
            case CampaignStatusEnum.Paused:
                return 'pause'
            case CampaignStatusEnum['Pending Approval']:
                return 'clock'
            case CampaignStatusEnum.Sent:
                return 'double-check'
            case CampaignStatusEnum.Failed:
                return 'error'
            case CampaignStatusEnum.Draft:
                return 'edit'
        }
    }

    get missingSteps () {
        const missingSteps = []
        if (this.$refs.vsEmailSenderCard && (this.$refs.vsEmailSenderCard.senderCardStatus === 'error' || this.$refs.vsEmailSenderCard.senderCardStatus === 'default')) missingSteps.push('sender')
        if (this.$refs.vsSmsSenderCard && (this.$refs.vsSmsSenderCard.senderCardStatus === 'error' || this.$refs.vsSmsSenderCard.senderCardStatus === 'default')) missingSteps.push('sender')
        if (this.$refs.vsEmailTargetCard.targetCardStatus === 'default') missingSteps.push('target')
        if (this.$refs.VsEmailSubjectCard && this.$refs.VsEmailSubjectCard.subjectCardStatus === 'default') missingSteps.push('subject')
        if (this.$refs.vsEmailContentCard.campaignContentStatus === 'error' || this.$refs.vsEmailContentCard.campaignContentStatus === 'default') missingSteps.push('content')
        return missingSteps
    }

    get campaignHasError () {
        return this.missingSteps.length > 0
    }

    get pauseErrorHeading () {
        return this.$t('campaigns.events.start') + ' ' + this.$t(`campaigns.events.${this.pauseError}`)
    }

    get minDate () {
        return moment().format()
    }

    get isBooster () {
        return this.campaign?.type === 'Booster'
    }

    async beforeMount () {
        await this.getCampaign()
        await this.getEmailTags()
    }

    beforeDestroy () {
        clearInterval(this.callLoop)
    }

    private async resetCampaign () {
        if (!this.campaign) return
        await this.getCampaign()
        this.setOrRemoveInterval(this.campaign.status)
    }

    @Watch('campaign.status', { immediate: false })
    setOrRemoveInterval (status: CampaignStatusEnum) {
        if (status === CampaignStatusEnum.Ready || status === CampaignStatusEnum.Sending) {
            clearInterval(this.callLoop)
            this.callLoop = setInterval(this.getCampaign, 10000)
        } else {
            clearInterval(this.callLoop)
        }
    }

    @Watch('campaignId', { immediate: false })
    private async getCampaignsAndCloseTabs () {
        this.openTab('')
        await this.getCampaign()
        await this.getEmailTags()
    }

    private async getCampaign () {
        this.showPianificationError = false
        this.loading = true
        try {
            const resp = await getCampaign(this.campaignId)
            this.campaign = resp.data.data
        } catch (e) {
            console.log(e)
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.$root.$vsToast({
                        timeout: 3000,
                        heading: 'Campagna non trovata',
                        aspect: VsToastAspectEnum.alert,
                    })
                    this.$router.replace({ name: 'campaignsIndex' })
                    return
                }
            }
        }
        this.loading = false
    }

    private openScheduleCampaignModal () {
        if (this.campaignHasError) {
            this.showPianificationError = true
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.notSchedulable'),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            return
        }
        this.showPianificationError = false
        this.openTab('')
        this.showScheduleCampaignModal = true
    }

    private async scheduleCampaign () {
        if (!this.campaign) return
        try {
            const data: ScheduleCampaignDto = {
                schedule_timestamp: this.schedule.now ? moment().unix() : moment(this.schedule.date).unix(),
                force_send: false,
            }
            await scheduleCampaign(this.campaignId, data)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.campaignScheduleSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getCampaign()
            this.showScheduleCampaignModal = false
        } catch (e) {
            this.getCampaign()
            const heading = this.$t('campaigns.editCampaign.toasts.campaignScheduleError')
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 406 && e.response.data.error === 'InsufficientCredit') {
                    message = this.$t('campaigns.editCampaign.toasts.campaignScheduleErrorInsufficientCreditMessage').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'UnsubscriptionLinkMissingInPlainContent') {
                    message = this.$t('campaigns.editCampaign.toasts.UnsubscriptionLinkMissingInPlainContent').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'CsaDataMissingInPlainContent') {
                    message = this.$t('campaigns.editCampaign.toasts.CsaDataMissingInPlainContent').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'SenderMustHaveCsaData') {
                    message = this.$t('campaigns.editCampaign.toasts.SenderMustHaveCsaData').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'SenderDkimNotValid') {
                    message = this.$t('campaigns.editCampaign.toasts.SenderDkimNotValid').toString()
                }
            }
            this.$root.$vsToast({
                heading,
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    private async pauseCampaign () {
        if (!this.campaign) return
        try {
            await this.$refs.confirmPause.openConfirm()
        } catch (e) {
            return
        }
        try {
            await pauseCampaign(this.campaignId)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.pauseCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.pauseCampaignError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.getCampaign()
    }

    private async abortCampaign () {
        if (!this.campaign) return
        try {
            await this.$refs.confirmAbort.openConfirm()
        } catch (e) {
            return
        }
        try {
            await abortCampaign(this.campaignId)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.abortCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.abortCampaignError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.getCampaign()
    }

    private async draftCampaign () {
        if (!this.campaign) return
        try {
            await this.$refs.confirmDraftAbort.openConfirm()
        } catch (e) {
            return
        }
        try {
            await abortCampaign(this.campaignId)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.draftCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.draftCampaignError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.getCampaign()
    }

    private async openConfirmDraft () {
        if (!this.campaign) return
        try {
            await this.$refs.confirmDraft.openConfirm()
        } catch (e) {
            return
        }
        try {
            await abortCampaign(this.campaignId)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.draftCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.showScheduleCampaignModal = false
        } catch (e) {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.draftCampaignError'),
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
        this.getCampaign()
    }

    private async forceResume () {
        try {
            await this.$refs.forceResumeConfirm.openConfirm()
        } catch (e) {
            return
        }
        this.resumeCampaign(true)
    }

    private async resumeCampaign (force?: boolean) {
        if (!this.campaign) return
        if (this.tabOpen !== '') {
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.closeBeforeSchedule'),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            return
        }
        if (!this.isAbSplit && this.campaignHasError) {
            this.showPianificationError = true
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.notSchedulable'),
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            return
        }
        try {
            const data: ScheduleCampaignDto = {
                schedule_timestamp: moment().unix(),
                force_send: force || false,
            }
            await scheduleCampaign(this.campaignId, data)
            this.$root.$vsToast({
                heading: this.$t('campaigns.editCampaign.toasts.resumeCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getCampaign()
        } catch (e) {
            const heading = this.$t('campaigns.editCampaign.toasts.resumeCampaignError')
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 406 && e.response.data.error === 'InsufficientCredit') {
                    message = this.$t('campaigns.editCampaign.toasts.campaignScheduleErrorInsufficientCreditMessage').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'UnsubscriptionLinkMissingInPlainContent') {
                    message = this.$t('campaigns.editCampaign.toasts.UnsubscriptionLinkMissingInPlainContent').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'CsaDataMissingInPlainContent') {
                    message = this.$t('campaigns.editCampaign.toasts.CsaDataMissingInPlainContent').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'SenderMustHaveCsaData') {
                    message = this.$t('campaigns.editCampaign.toasts.SenderMustHaveCsaData').toString()
                }
                if (e.response?.status === 406 && e.response.data.error === 'SenderDkimNotValid') {
                    message = this.$t('campaigns.editCampaign.toasts.SenderDkimNotValid').toString()
                }
            }

            this.$root.$vsToast({
                heading,
                message,
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
    }

    async updateName (e: any) {
        if (!this.campaign) return
        try {
            await updateCampaign(this.campaignId, {
                name: e.name,
            })
            this.$refs.campaignNameModal.closeModal()
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.updateNameCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
            })
            this.getCampaign()
        } catch (e) {
            const toast = this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.generalUpdateCampaignError'),
                aspect: VsToastAspectEnum.alert,
            })
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    const message = get(e, 'response.data.message.name', [''])
                    if (message[0] === 'The name may not be greater than 250 characters.') {
                        toast.heading = this.$t('campaigns.editCampaign.toasts.maxLengthCampaignNameError')
                    }
                }
            }
            this.$refs.campaignNameModal.loading = false
            console.log(e)
        }
    }

    private async deleteCampaign () {
        try {
            await this.$refs.confirmDeleteCampaign.openConfirm()
            this.$refs.confirmDeleteCampaign.closeConfirm()
        } catch (e) {
            this.$refs.confirmDeleteCampaign.closeConfirm()
            return
        }

        this.loading = true
        try {
            await deleteCampaign(this.campaignId)
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.deleteCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
            })
            this.$router.push({
                name: 'campaignsIndex',
            })
        } catch (e) {
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.deleteCampaignError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    private async cloneCampaign () {
        try {
            const duplicatedCampaign = await duplicateCampaign(this.campaignId)
            const newCampaign = await getCampaign(duplicatedCampaign.data)
            if (newCampaign.data.data.content_type === 'email') {
                const emailData = newCampaign.data.data.message.data
                await this.generatePreviewImage(emailData.id, emailData.html_content)
            }
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.toasts.duplicateCampaignSuccess'),
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            const errorCode = get(e, 'response.data.message', 0)
            let message = ''
            if (errorCode === 'You don\'t have abTest permission') {
                message = 'Non puoi duplicare la campagna perchè non hai la funzionalità A/B split attiva'
            }
            this.$root.$vsToast({
                timeout: 3000,
                message,
                heading: this.$t('campaigns.editCampaign.toasts.duplicateCampaignError'),
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private async generatePreviewImage (emailId: string, htmlContent: string) {
        try {
            const iframe = document.createElement('iframe')
            iframe.style.position = 'absolute'
            iframe.style.left = '-9999px'
            iframe.style.top = '-9999px'
            iframe.style.width = '700px'
            iframe.style.height = '800px'
            document.body.appendChild(iframe)

            await new Promise(resolve => {
                iframe.onload = resolve
                const doc = iframe.contentDocument || iframe.contentWindow?.document
                if (doc) {
                    doc.open()
                    doc.write(htmlContent)
                    doc.close()
                }
            })

            const canvas = await html2canvas(iframe.contentDocument?.body || iframe, {
                windowWidth: 700,
                width: 700,
                height: 800,
                proxy: '/app/userapi/users/screenshot-images-proxy',
            })

            document.body.removeChild(iframe)
            let base64 = canvas.toDataURL('image/jpeg', 0.1)
            base64 = await processImage(base64)

            await uploadThumbnailImage(emailId, {
                base64_image: base64,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio dell\'anteprima',
                timeout: 3000,
                aspect: VsToastAspectEnum.warning,
            })
            console.log(e)
        }
    }

    private async getEmailTags () {
        if (!this.campaign || this.campaign.content_type !== 'email') return

        try {
            const resp = await getEmailTags(this.campaign.message.data.id)
            this.mappedTags = resp.data.data
        } catch (e) {
            this.mappedTags = []
            console.log(e)
        }
    }
}
