






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsBlockedFunctionalityModal from '@/components/VsBlockedFunctionalityModal/Index.vue'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsPercentageBar from '@/modules/mediaLibrary/components/VsPercentageBar/Index.vue'
import { AppModule } from '@/store/modules/app'
import { CampaignStatusEnum } from '@/api/consoleApi/dto/campaigns.dto'
import { convertMsToTime, formatDate, formatUnixDate, formatNumber } from '@/utils/formatter'
import moment from 'moment'

export interface ISchedule {
    now: boolean
    date: Date
}

@Component({
    name: 'VsCampaignStatusCard',
    components: {
        VsBlockedFunctionalityModal,
        VsListingCard,
        VsPercentageBar,
    },
})

export default class extends Vue {
    @Prop({
        default: null,
        required: true,
    }) private campaign!: any

    @Prop({
        default: false,
        required: false,
    }) private loading!: boolean

    get is4Dem () {
        return AppModule.consoleConf?.is4Dem
    }

    get titleClasses () {
        return {
            'vs-text-info-500': this.campaign.status === CampaignStatusEnum.Ready,
            'vs-text-primary-800': this.campaign.status === CampaignStatusEnum.Sending,
            'vs-text-success-900': this.campaign.status === CampaignStatusEnum.Sent,
            'vs-text-warning-900': this.campaign.status === CampaignStatusEnum.Paused,
            'vs-text-alert-900': this.campaign.status === CampaignStatusEnum.Failed,
            'vs-text-grey-900': this.campaign.status === CampaignStatusEnum['Pending Approval'],
        }
    }

    get iconColor () {
        switch (this.campaign.status) {
            case CampaignStatusEnum.Ready:
                return 'info-500'
            case CampaignStatusEnum.Sending:
                return 'primary-800'
            case CampaignStatusEnum.Paused:
                return 'warning-900'
            case CampaignStatusEnum['Pending Approval']:
                return 'grey-900'
            case CampaignStatusEnum.Sent:
                return 'success-900'
            case CampaignStatusEnum.Failed:
                return 'alert-900'
            default:
                return 'grey-900'
        }
    }

    get icon () {
        switch (this.campaign.status) {
            case CampaignStatusEnum.Ready:
                return 'clock'
            case CampaignStatusEnum.Sending:
                return 'outgoing'
            case CampaignStatusEnum.Paused:
                return 'pause'
            case CampaignStatusEnum['Pending Approval']:
                return 'clock'
            case CampaignStatusEnum.Sent:
                return 'double-check'
            case CampaignStatusEnum.Failed:
                return 'error'
            default:
                return 'clock'
        }
    }

    get scheduleOrNow () {
        if (this.campaign.scheduled) {
            return `${formatUnixDate(this.campaign.scheduled, 'DD MMMM YYYY')} ${this.$t('campaigns.editCampaign.campaignStatusCard.at')} ${formatUnixDate(this.campaign.scheduled, 'HH:mm')}`
        } else {
            return `${formatDate(new Date(), 'DD MMMM YYYY')} ${this.$t('campaigns.editCampaign.campaignStatusCard.at')} ${formatDate(new Date(), 'HH:mm')}`
        }
    }

    get percentage () {
        if (!this.campaign || this.campaign.total_recipients === 0 || this.campaign.total_sent === 0) return '0%'
        const percentage = Math.round((this.campaign.total_sent / this.campaign.total_recipients) * 100)
        return `${percentage}%`
    }

    get titleText () {
        switch (this.campaign?.status) {
            case CampaignStatusEnum.Ready:
                return `${this.$t('campaigns.editCampaign.campaignStatusCard.campaignScheduledAt')} ${this.scheduleOrNow}`
            case CampaignStatusEnum.Sending:
                return this.$t('campaigns.editCampaign.campaignStatusCard.campaignSending')
            case CampaignStatusEnum.Sent:
                return this.$tc('campaigns.editCampaign.campaignStatusCard.campaignSent', this.campaign.total_sent, { count: formatNumber(this.campaign.total_sent) })
            case CampaignStatusEnum.Paused:
                return this.campaign.status_reason === 'AbSplitWaitingForWinner'
                    ? 'La campagna è in pausa, in attesa del risultato del test.'
                    : `${this.$t(`campaigns.events.start`)}, ${this.$tc('campaigns.editCampaign.campaignStatusCard.campaignSentAt', this.campaign.total_sent, { count: formatNumber(this.campaign.total_sent) })} ${this.$tc('campaigns.editCampaign.campaignStatusCard.campaignSentExpected', this.campaign.total_recipients, { count: formatNumber(this.campaign.total_recipients) })}`
            case CampaignStatusEnum['Pending Approval']:
                return this.$t('campaigns.editCampaign.campaignStatusCard.campaignPendingApproval')
            case CampaignStatusEnum.Failed:
                return `${this.$t('campaigns.editCampaign.campaignStatusCard.campaignFailed')}, ${this.$tc('campaigns.editCampaign.campaignStatusCard.campaignSentAt', this.campaign.total_sent, { count: formatNumber(this.campaign.total_sent) })} ${this.$tc('campaigns.editCampaign.campaignStatusCard.campaignSentExpected', this.campaign.total_recipients, { count: formatNumber(this.campaign.total_recipients) })}`
            default: return ''
        }
    }

    get subtitleText () {
        switch (this.campaign?.status) {
            case CampaignStatusEnum.Ready:
                return ''
            case CampaignStatusEnum.Sending:
            case CampaignStatusEnum.Paused:
                return this.campaign.status_reason === 'AbSplitWaitingForWinner'
                    ? 'La campagna riprenderà l\'invio automaticamente alla fine della durata del test'
                    : this.$t('campaigns.editCampaign.campaignStatusCard.campaignStartedAt', {
                        hours: formatDate(this.campaign.sent_start, 'DD MMMM YYYY'),
                        minutes: formatDate(this.campaign.sent_start, 'HH:mm'),
                    })
            case CampaignStatusEnum['Pending Approval']:
                return `${this.$t('campaigns.editCampaign.campaignStatusCard.campaignScheduledAt')} ${this.scheduleOrNow}`
            case CampaignStatusEnum.Sent:
            case CampaignStatusEnum.Failed:
                return `${this.$t('campaigns.editCampaign.campaignStatusCard.campaignStartedAt', {
                    hours: formatDate(this.campaign.sent_start, 'DD MMMM YYYY'),
                    minutes: formatDate(this.campaign.sent_start, 'HH:mm'),
                })}${this.calculateDuration()}`
            default: return ''
        }
    }

    private calculateDuration () {
        if (this.campaign.sent_start === '0000-00-00 00:00:00' || this.campaign.sent_finish === '0000-00-00 00:00:00') return ''
        const start = moment(this.campaign.sent_start)
        const end = moment(this.campaign.sent_finish)
        const duration = convertMsToTime(end.diff(start))
        return ` ${this.$t('campaigns.editCampaign.campaignStatusCard.campaignDuration')} ${duration}`
    }
}
