





























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsOverlayCard from '@/components/VsOverlayCard/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import { IMessageEmail } from '@/api/consoleApi/dto/campaigns.dto'
import VsDragoTemplateModal from '@/modules/campaigns/components/VsDragoTemplateModal/Index.vue'
import VsDragoModal from '@/modules/campaigns/components/VsDragoModal/Index.vue'
import VsEmailContentTextEditorModal from '@/modules/campaigns/components/VsEmailContentTextEditorModal/Index.vue'
import VsImportHtmlModal from '@/modules/campaigns/components/VsImportHtmlModal/Index.vue'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import VsSpamTestModalModal from '@/modules/campaigns/components/VsSpamTestModalModal/Index.vue'
import VsInlineErrorsCheck from '@/modules/campaigns/components/VsInlineErrorsCheck/Index.vue'
import VsHintCard from '@/modules/campaigns/components/VsHintCard/Index.vue'
import VsEmailPlainContentModal from '@/modules/campaigns/components/VsEmailPlainContentModal/Index.vue'
import VsMapTagModal from '@/modules/campaigns/components/VsMapTagModal/Index.vue'
import { CustomField } from '@/utils/customFields'
import { UserModule } from '@/store/modules/user'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsMessageModal from '@/components/VsMessageModal/Index.vue'
import VsEmailCard from '@/modules/campaigns/components/VsEmailCard/Index.vue'
import {
    getSplitTestCampaignEmails,
    addSplitTestCampaignEmail,
    deleteSplitTestCampaignEmail,
    duplicateSplitTestCampaignEmail,
} from '@/api/consoleApi/campaigns'
import { getEmail, getEmailPreview, uploadThumbnailImage } from '@/api/consoleApi/contents/emails'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { get } from 'lodash'
import { downloadHtml } from '@/utils/export'
import VsSaveModelModal from '@/modules/campaigns/components/VsSaveModelModal/Index.vue'
import { processImage } from '@/utils/screenshot'
import html2canvas from 'html2canvas'

@Component({
    name: 'VsSplitTestEmailContentCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsConfirm,
        VsDragoTemplateModal,
        VsDragoModal,
        VsEmailContentTextEditorModal,
        VsImportHtmlModal,
        VsOverlayCard,
        VsPreviewModal,
        VsInlineErrorsCheck,
        VsEmailPlainContentModal,
        VsHintCard,
        VsSpamTestModalModal,
        VsMapTagModal,
        VsActionCard,
        VsMessageModal,
        VsSaveModelModal,
        VsEmailCard,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) splitTest!: any
    @Prop({ required: true, default: null }) campaignStatus!: any
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: true, default: () => [] }) customFields!: CustomField[]
    @Prop({ required: false, default: () => [] }) lists!: any[]
    @Prop({ required: false, default: true, type: Boolean }) isEditable!: boolean
    @Prop({ required: false, default: false, type: Boolean }) showEditButton!: boolean

    private contentError = false
    private loading = false
    errors: any[] = []
    emails: IMessageEmail[] = []

    $refs!: {
        vsMessageModal: VsMessageModal
        previewModal: VsPreviewModal
        emailCard: VsEmailCard[]
        saveModelModal: VsSaveModelModal
    }

    openTab () {
        this.$emit('open-tab', 'content')
    }

    get campaignContentStatus () {
        if (this.emails.length === 0) return 'default'
        if (this.emails.length < 2) return 'error'
        return this.errors.length > 0 ? 'error' : 'success'
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get user () {
        return UserModule.user
    }

    get emailWinnerId () {
        return this.splitTest?.winner_message?.EmailID || ''
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    @Watch('campaignId', { immediate: true })
    async getSplitTestEmails () {
        if (!this.campaignId) return
        try {
            const resp = await getSplitTestCampaignEmails(this.campaignId)
            this.emails = resp.data.data.map((el: any) => el.email.data)
        } catch (e) {
            console.log(e)
        }
    }

    async openModal (emailId: any, title?: any) {
        if (!this.$refs?.vsMessageModal) {
            this.loading = false
            return
        }
        try {
            await this.$refs.vsMessageModal.openModal({
                messageId: emailId,
                contentType: 'email',
                containerId: this.campaignId,
                containerType: 'campaignSplit',
                title: title || 'Email senza nome',
                listId: this.lists[0]?.id || 0,
            })
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    async addSplitTestCampaignEmail () {
        this.loading = true
        try {
            const resp = await addSplitTestCampaignEmail(this.campaignId, { name: 'Email senza nome' })
            await this.getSplitTestEmails()
            this.$root.$vsToast({
                heading: 'Email creata con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            const emailId = resp.data
            this.openModal(emailId)
        } catch (e) {
            const errorCode = get(e, 'response.data.error', '')
            let message = ''
            if (errorCode === 'SplitTestEmailLimitReached') {
                message = 'Hai raggiunto il limite massimo di email creabili per questa campagna'
            }

            this.$root.$vsToast({
                heading: 'Errore durante la creazione della mail',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
            this.loading = false
        }
    }

    async deleteSplitTestCampaignEmail (emailId: any) {
        this.loading = true
        try {
            await deleteSplitTestCampaignEmail(this.campaignId, emailId)
            await this.getSplitTestEmails()
            this.$root.$vsToast({
                heading: 'Email eliminata con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            const index = this.errors.indexOf(emailId)
            if (index > -1) {
                this.errors.splice(index, 1)
            }
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione della mail',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    async duplicateSplitTestCampaignEmail (emailId: any) {
        this.loading = true
        try {
            const duplicatedEmail = await duplicateSplitTestCampaignEmail(this.campaignId, emailId)
            const newEmail = await getEmail(duplicatedEmail.data)
            const emailData = newEmail.data.data
            await this.generatePreviewImage(emailData.id, emailData.html_content)

            await this.getSplitTestEmails()
            this.$root.$vsToast({
                heading: 'Email duplicata con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            const index = this.errors.indexOf(emailId)
            if (index > -1) {
                this.errors.splice(index, 1)
            }
        } catch (e) {
            const errorCode = get(e, 'response.data.error', '')
            let message = ''
            if (errorCode === 'SplitTestEmailLimitReached') {
                message = 'Hai raggiunto il limite massimo di email creabili per questa campagna'
            }
            this.$root.$vsToast({
                heading: 'Errore durante la duplicazione della mail',
                message,
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
            console.log(e)
        }
        this.loading = false
    }

    private async generatePreviewImage (emailId: string, htmlContent: string) {
        try {
            const iframe = document.createElement('iframe')
            iframe.style.position = 'absolute'
            iframe.style.left = '-9999px'
            iframe.style.top = '-9999px'
            iframe.style.width = '700px'
            iframe.style.height = '800px'
            document.body.appendChild(iframe)

            await new Promise(resolve => {
                iframe.onload = resolve
                const doc = iframe.contentDocument || iframe.contentWindow?.document
                if (doc) {
                    doc.open()
                    doc.write(htmlContent)
                    doc.close()
                }
            })

            const canvas = await html2canvas(iframe.contentDocument?.body || iframe, {
                windowWidth: 700,
                width: 700,
                height: 800,
                proxy: '/app/userapi/users/screenshot-images-proxy',
            })

            document.body.removeChild(iframe)
            let base64 = canvas.toDataURL('image/jpeg', 0.1)
            base64 = await processImage(base64)

            await uploadThumbnailImage(emailId, {
                base64_image: base64,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante il salvataggio dell\'anteprima',
                timeout: 3000,
                aspect: VsToastAspectEnum.warning,
            })
            console.log(e)
        }
    }

    async getEmail (emailId: any) {
        try {
            const resp = await getEmail(emailId, { include: 'footer' })
            this.emails = this.emails.map(email => {
                if (email.id === emailId) {
                    return resp.data.data
                }
                return email
            })
            this.$refs.vsMessageModal.email = resp.data.data
        } catch (e) {
            console.log(e)
        }
    }

    setErrors (messageId: any, hasErrors: boolean) {
        const index = this.errors.indexOf(messageId)
        if (hasErrors && index === -1) {
            this.errors.push(messageId)
        }
        if (!hasErrors && index > -1) {
            this.errors.splice(index, 1)
        }
    }

    public openPreviewModal (email: any) {
        this.$refs.previewModal.openModalNew(email, 'email')
    }

    private async exportHtml (email: any) {
        try {
            const resp = await getEmailPreview(email.id)
            downloadHtml(resp.data, email.email_name)
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                timeout: 3000,
                heading: 'Errore durante l\'export dell\'html',
                aspect: VsToastAspectEnum.alert,
            })
        }
    }

    private saveAsModel (emailId: any) {
        this.$refs.saveModelModal.openModal(emailId)
    }
}
