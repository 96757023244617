







































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getLists } from '@/api/consoleApi/recipients'
import axios from 'axios'
import { UserModule } from '@/store/modules/user'
import { get, groupBy, orderBy } from 'lodash'
import VsActionCard from '@/components/VsActionCard/Index.vue'

@Component({
    name: 'VsSelectListModal',
    components: {
        VsActionCard,
    },
})
export default class extends Vue {
    @Prop({ default: () => [], required: false }) sharedLists!: any[]
    private show = false
    private loading = false
    private cancelTokenSource = axios.CancelToken.source()
    private lists = []
    private total = 0
    private selectedList: any = null
    private listsToExclude: any = []
    private shareOptions = [
        {
            value: 'notShare',
            label: this.$t('lists.index.shareOptions.yourLists'),
        },
        {
            value: 'share',
            label: this.$t('lists.index.shareOptions.sharedList'),
        },
    ]

    get filterSharedLists () {
        let order: 'asc' | 'desc' = 'desc'
        if (this.pagination.orderBy) {
            const splitted = this.pagination.orderBy.split('|')
            order = splitted[1]
        }
        return orderBy(
            Object.values(
                groupBy(this.sharedLists, 'recipient_id'),
            ).filter((el: any) => {
                return el[0].recipient && el[0].recipient.name.toLowerCase().includes(this.filters.search.toLowerCase())
            }).map((el: any) => {
                return {
                    ...el[0].recipient,
                    shared: true,
                }
            }),
            'id',
            order,
        )
    }

    get totalLists () {
        if (this.filters.share === 'share') {
            return this.filterSharedLists.length
        } else {
            return this.total
        }
    }

    get userRules () {
        return UserModule.user.configuration.rules
    }

    get canShareRecipient () {
        return this.userRules.shareRecipients
    }

    get listsOrderBy () {
        return [
            {
                label: this.$t('lists.index.orderBy.createdAtDesc'),
                value: 'created_at|desc',
            },
            {
                label: this.$t('lists.index.orderBy.createdAtAsc'),
                value: 'created_at|asc',
            },
        ]
    }

    public openModal (listsToExclude: any[]) {
        this.selectedList = null
        this.show = true
        this.listsToExclude = listsToExclude
        this.getLists()
    }

    public closeModal () {
        this.show = false
    }

    private filters = {
        search: '',
        tags: [],
        share: 'notShare',
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.page = 1
        this.getLists()
    }

    @Watch('pagination', { deep: true, immediate: false })
    async getLists () {
        if (this.filters.share === 'share') return

        this.loading = true
        try {
            if (this.cancelTokenSource) {
                this.cancelTokenSource.cancel('cancel-token')
            }
            this.cancelTokenSource = axios.CancelToken.source()
            // const resp = await getLists(this.buildParams(), this.cancelTokenSource.token)
            const resp = await getLists(this.buildParams(), this.cancelTokenSource.token)
            this.lists = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            const message = get(e, 'message', '')
            if (message === 'cancel-token') return
            this.lists = []
            this.total = 0
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.search.trim() !== '' ? `name:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private isChecked (list: any) {
        return this.selectedList?.id === list.id
    }

    selectList (list: any) {
        this.selectedList = list
    }

    addListToCampaign () {
        this.$emit('list-selected', this.selectedList)
        this.closeModal()
    }

    private exclude (list: any) {
        return !!this.listsToExclude.find((el: any) => el.id === list.id)
    }
}
