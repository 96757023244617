


























































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsCollapseCard from '@/components/VsCollapseCard/Index.vue'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { ICampaign } from '@/api/consoleApi/dto/campaigns.dto'
import { getListCustomFields, getSharedLists, getList, getListSegmentById, getTagById } from '@/api/consoleApi/recipients'
import {
    getCampaignLists,
    setCampaignLists,
} from '@/api/consoleApi/campaigns'
// import { groupBy } from 'lodash'
import axios from 'axios'
import { get } from 'lodash'
import { estimateCampaignContacts } from '@/api/console'
import { formatNumber } from '@/utils/formatter'
import VsSelectListModal from '@/modules/campaigns/components/VsSelectListModal/Index.vue'
import VsSelectListFiltersModal from '@/modules/campaigns/components/VsSelectListFiltersModal/Index.vue'
import VsSelectSharedListFiltersModal from '@/modules/campaigns/components/VsSelectSharedListFiltersModal/Index.vue'
import { AppModule } from '@/store/modules/app'
import VsContactsFilterMock from '@/modules/lists/components/VsContactsFilterMock/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import { setEmailTags } from '@/api/consoleApi/contents/emails'

@Component({
    name: 'VsEmailTargetCard',
    components: {
        VsLoader,
        VsCollapseCard,
        VsSelectListModal,
        MyAccordion,
        VsSelectListFiltersModal,
        VsSelectSharedListFiltersModal,
        VsContactsFilterMock,
        VsDropdownButton,
        VsConfirm,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) campaign!: ICampaign
    @Prop({ required: true, default: '' }) tabOpen!: string
    @Prop({ required: false, default: false }) isBooster!: boolean
    @Prop({ required: false, default: () => [] }) mappedTags!: any[]
    private loading = false
    private loadingTotalCont = false
    private totalContacts: number | null = null

    private lists: any[] = []
    private sharedLists: any[] = []
    private selectedLists: any[] = []
    private tempSelectedLists: any[] = []

    private profilations: any = {}
    private tags: any = {}
    private addToExclusion = false

    private selectedFilterLists: any[] = []
    private tempSelectedFilterLists: any[] = []

    private openFilters: any[] = []
    private exclusionOpenFilters: any[] = []

    $refs: any

    toggleShowFilters (id: any) {
        const i = this.openFilters.findIndex(el => el === id)
        if (i === -1) {
            this.openFilters.push(id)
        } else {
            this.openFilters.splice(i, 1)
        }
    }

    toggleExclusionShowFilters (id: any) {
        const i = this.exclusionOpenFilters.findIndex(el => el === id)
        if (i === -1) {
            this.exclusionOpenFilters.push(id)
        } else {
            this.exclusionOpenFilters.splice(i, 1)
        }
    }

    get isSmViewport () {
        return AppModule.isSmViewport
    }

    get user () {
        return UserModule.user
    }

    get hasTag () {
        return this.user.configuration.rules.tag
    }

    get hasMultiList () {
        return UserModule.user.configuration.rules.sendListMultiple
    }

    get campaignId () {
        return this.$route.params.campaignId || ''
    }

    get selectedListName () {
        if (this.lists.length === 0 || this.selectedLists.length === 0) return ''
        return this.selectedLists[0].name
    }

    get selectedFilterListsInfo () {
        return this.lists.filter(el => this.selectedFilterLists.includes(el.id))
    }

    get listsOptions () {
        return this.lists.map((e: any) => {
            return {
                value: e.id,
                label: e.name,
            }
        })
    }

    get estimateContact () {
        return this.totalContacts || this.campaign.estimate_sent || 0
    }

    get selectedListsCount () {
        let countList = 0
        let countFilters = 0
        this.selectedLists.forEach(el => {
            if (el.profilations.length === 0 && el.tags.length === 0) {
                countList = countList + 1
            }
            countFilters = countFilters + el.profilations.length + el.tags.length
        })

        return `${this.$tc('campaigns.editCampaign.sections.target.selectedListsSubtitle', countList, { count: formatNumber(countList) })}${countList > 0 && countFilters > 0 ? ' e ' : ''}${this.$tc('campaigns.editCampaign.sections.target.selectedProfilationsSubtitle', countFilters, { count: formatNumber(countFilters) })}`
    }

    get selectedExclusionListsCount () {
        let countList = 0
        let countFilters = 0
        this.selectedFilterLists.forEach(el => {
            if (el.profilations.length === 0 && el.tags.length === 0) {
                countList = countList + 1
            }
            countFilters = countFilters + el.profilations.length + el.tags.length
        })

        return `${this.$tc('campaigns.editCampaign.sections.target.exclusion')} ${this.$tc('campaigns.editCampaign.sections.target.selectedListsSubtitle', countList, { count: formatNumber(countList) })}${countList > 0 && countFilters > 0 ? ' e ' : ''}${this.$tc('campaigns.editCampaign.sections.target.selectedProfilationsSubtitle', countFilters, { count: formatNumber(countFilters) })}`
    }

    private profilationsOptions (listId: any) {
        if (!this.profilations[listId]) return []
        return this.profilations[listId].map((e: any) => {
            return {
                value: e.id,
                label: e.name,
            }
        })
    }

    @Watch('campaignId', { immediate: false })
    private async getCampaignLists () {
        try {
            const resp = await getCampaignLists(this.campaignId)
            this.selectedLists = await this.formatSelectedLists(resp.data.data.filter((el: any) => !el.exclude))
            this.selectedFilterLists = await this.formatSelectedLists(resp.data.data.filter((el: any) => el.exclude))
            if (this.selectedLists.length > 0 && this.selectedLists[0]) {
                const res = await getListCustomFields(this.selectedLists[0].id, { limit: 10000 })
                this.$emit('custom-fields-changed', res.data.data)
            } else {
                this.$emit('custom-fields-changed', [])
            }
            this.$emit('selected-lists-updated', this.selectedLists)
        } catch (e) {
            console.log(e)
        }
    }

    // parse from db data to app data
    private async formatSelectedLists (data: any[]) {
        // LOGICA CUSTOM
        const selectedLists: any[] = []
        for (const el of data) {
            const list = await this.getList(el.recipient_id)

            const index = selectedLists.findIndex(elm => el.recipient_id === elm.id)

            let prof: any
            if (el.segment_id !== 0) {
                prof = await this.getListSegment(el.recipient_id, el.segment_id)
            }
            let tag: any
            if (el.tag_id !== 0) {
                tag = await this.getListTag(el.recipient_id, el.tag_id)
            }

            if (index === -1) {
                selectedLists.push({
                    id: list?.id || el.recipient_id,
                    shared: list.shared,
                    name: list?.name || this.$t('campaigns.editCampaign.sections.target.listNotFound'),
                    profilations: prof ? [{
                        id: prof?.id || el.segment_id,
                        name: prof?.name || this.$t('campaigns.editCampaign.sections.target.profilationNotFound'),
                    }] : [],
                    tags: tag ? [{
                        id: tag?.id || el.tag_id,
                        name: tag?.name || this.$t('campaigns.editCampaign.sections.target.profilationNotFound'), // TODO tag not found
                    }] : [],
                })
            } else {
                if (el.segment_id !== 0) {
                    selectedLists[index].profilations.push({
                        id: prof?.id || el.segment_id,
                        name: prof?.name || this.$t('campaigns.editCampaign.sections.target.profilationNotFound'),
                    })
                }
                if (el.tag_id !== 0) {
                    selectedLists[index].tags.push({
                        id: tag?.id || el.tag_id,
                        name: tag?.name || this.$t('campaigns.editCampaign.sections.target.profilationNotFound'), // TODO tag not found
                    })
                }
            }
        }

        return selectedLists
    }

    get isListSectionCorrect () {
        for (const el of this.tempSelectedLists) {
            if (el.shared && this.sharedListHasErrors(el)) {
                return false
            }
        }
        return this.tempSelectedLists.length > 0
    }

    get isExclusionListSectionCorrect () {
        for (const el of this.tempSelectedFilterLists) {
            if ((el.shared && this.sharedListHasErrors(el)) || this.listIsDuplicated(el)) {
                return false
            }
            for (const tag of el.tags) {
                if (this.filterIsDuplicated(el, tag, 'tags')) {
                    return false
                }
            }
            for (const profilation of el.profilations) {
                if (this.filterIsDuplicated(el, profilation, 'profilations')) {
                    return false
                }
            }
        }
        return true
    }

    get flatSelectedListsAndFilters () {
        const allListAndProfilations: any[] = []
        this.selectedLists.forEach(el => {
            if (el.profilations && el.profilations.length > 0) {
                allListAndProfilations.push(...el.profilations.map((elm: any) => {
                    return {
                        ...elm,
                        name: `${el.name} > ${elm.name}`,
                        type: 'Profilazione',
                    }
                }))
            }
            if (el.tags && el.tags.length > 0) {
                allListAndProfilations.push(...el.tags.map((elm: any) => {
                    return {
                        ...elm,
                        name: `${el.name} > ${elm.name}`,
                        type: 'Etichetta',
                    }
                }))
            }

            if (el.tags && el.tags.length === 0 && el.profilations && el.profilations.length === 0) {
                allListAndProfilations.push({
                    ...el,
                    name: `${el.name}`,
                    type: 'Tutta la lista',
                })
            }
        })
        return allListAndProfilations
    }

    get flatSelectedExclusionListsAndProfilations () {
        const allListAndProfilations: any[] = []
        this.selectedFilterLists.forEach(el => {
            if (el.profilations && el.profilations.length > 0) {
                allListAndProfilations.push(...el.profilations.map((elm: any) => {
                    return {
                        ...elm,
                        name: `${el.name} > ${elm.name}`,
                        type: 'Profilazione',
                    }
                }))
            }
            if (el.tags && el.tags.length > 0) {
                allListAndProfilations.push(...el.tags.map((elm: any) => {
                    return {
                        ...elm,
                        name: `${el.name} > ${elm.name}`,
                        type: 'Etichetta',
                    }
                }))
            }

            if (el.tags && el.tags.length === 0 && el.profilations && el.profilations.length === 0) {
                allListAndProfilations.push({
                    ...el,
                    name: `${el.name}`,
                    type: 'Tutta la lista',
                })
            }
        })
        return allListAndProfilations
    }

    get targetCardStatus () {
        if (this.selectedLists.length === 0) return 'default'
        return 'success'
    }

    get hasMocks () {
        return AppModule.hasMocks
    }

    async beforeMount () {
        await this.getLists()
        await this.getCampaignLists()
        if (this.selectedLists.length > 0 && this.estimateContact === 0) {
            this.calculateTotalContacts()
        }
    }

    openTab () {
        this.$emit('open-tab', 'target')
    }

    async calculateTotalContacts () {
        this.loadingTotalCont = true
        try {
            const resp = await estimateCampaignContacts(this.campaignId)
            this.totalContacts = resp.data.total
        } catch (e) {
            this.totalContacts = null
            console.log(e)
        }
        this.loadingTotalCont = false
    }

    async getLists () {
        try {
            // const response = await getLists({
            //     limit: 10000,
            // })
            const resp = await getSharedLists()
            // const lists = response.data.data
            this.sharedLists = resp.data.data
            const lists: any[] = []
            this.setSharedListAndProfilations(lists, resp.data.data)
            this.lists = lists
        } catch (e) {
            this.lists = []
            this.sharedLists = []
            console.log(e)
        }
    }

    async getList (listId: any) {
        let list: any = this.lists.find(elm => {
            return elm.id === listId
        })

        if (!list) {
            try {
                const resp = await getList(listId)
                list = resp.data.data
                this.lists.push(list)
            } catch (e) {
                console.log(e)
            }
        }

        return list
    }

    async getListSegment (listId: any, segmentId: any) {
        if (this.profilations[listId]) {
            const prof = this.profilations[listId].find((elm: any) => {
                return segmentId === elm.id
            })
            if (prof) return prof
        }
        try {
            const response = await getListSegmentById(
                listId,
                segmentId,
            )
            this.profilations = {
                ...this.profilations,
                [listId]: [
                    ...this.profilations[listId] || [],
                    response.data.data,
                ],
            }
            return response.data.data
        } catch (e) {
            console.log(e)
            return null
        }
    }

    async getListTag (listId: any, tagId: any) {
        if (this.tags[listId]) {
            const tag = this.tags[listId].find((elm: any) => {
                return tagId === elm.id
            })
            if (tag) return tag
        }
        try {
            const response = await getTagById(
                listId,
                tagId,
            )
            this.tags = {
                ...this.tags,
                [listId]: [
                    ...this.tags[listId] || [],
                    response.data.data,
                ],
            }
            return response.data.data
        } catch (e) {
            console.log(e)
            return null
        }
    }

    private setSharedListAndProfilations (lists: any[], sharedLists: any[]) {
        const sharedListsToAdd: any[] = []
        const sharedRecipients: any = {}
        sharedLists.forEach(shared => {
            const listId = shared.recipient_id
            const segmentId = shared.segment_id
            if (shared.recipient && !sharedListsToAdd.find(list => list.id === listId)) {
                sharedListsToAdd.push({
                    ...shared.recipient,
                    shared: true,
                })
            }
            if (shared.recipient && shared.segment && segmentId > 0) {
                if (sharedRecipients[listId]) {
                    sharedRecipients[listId].push({
                        ...shared.segment,
                        shared: true,
                    })
                } else {
                    sharedRecipients[listId] = [{
                        ...shared.segment,
                        shared: true,
                    }]
                }
            }
        })
        this.profilations = {
            ...this.profilations,
            ...sharedRecipients,
        }
        lists.push(...sharedListsToAdd)
    }

    private filterListsWithoutSelected (currentListId: any) {
        return this.listsOptions.filter(el => !this.tempSelectedLists.find(elm => elm.id === el.value) || el.value === currentListId)
    }

    private filterExclusionListsWithoutSelected (currentListId: any) {
        return this.listsOptions.filter(el => !this.tempSelectedFilterLists.find(elm => elm.id === el.value) || el.value === currentListId)
    }

    private async selectList (list: any) {
        const listToAdd = {
            id: list.id,
            name: list.name,
            shared: list.shared,
            profilations: [],
            tags: [],
            show: false,
        }
        if (this.addToExclusion) {
            this.tempSelectedFilterLists.push(listToAdd)
        } else {
            this.tempSelectedLists.push(listToAdd)
        }
    }

    private selectFilters (e: { tags: any[], profilations: any[], listId: any }) {
        let list
        if (this.addToExclusion) {
            list = this.tempSelectedFilterLists.find(el => el.id === e.listId)
        } else {
            list = this.tempSelectedLists.find(el => el.id === e.listId)
        }
        if (list) {
            list.profilations.push(...e.profilations)
            list.tags.push(...e.tags)
        }
    }

    private removeList (index: number) {
        this.tempSelectedLists.splice(index, 1)
        if (this.tempSelectedLists.length === 0) this.tempSelectedFilterLists = []
    }

    private removeExclusionList (index: number) {
        this.tempSelectedFilterLists.splice(index, 1)
    }

    private removeProfilation (index: number, filterIndex: number) {
        this.tempSelectedLists[index].profilations.splice(filterIndex, 1)
    }

    private removeExclusionProfilation (index: number, filterIndex: number) {
        this.tempSelectedFilterLists[index].profilations.splice(filterIndex, 1)
    }

    private removeTag (index: number, filterIndex: number) {
        this.tempSelectedLists[index].tags.splice(filterIndex, 1)
    }

    private removeExclusionTag (index: number, filterIndex: number) {
        this.tempSelectedFilterLists[index].tags.splice(filterIndex, 1)
    }

    private openTabTarget () {
        this.tempSelectedLists = JSON.parse(JSON.stringify(this.selectedLists))
        this.tempSelectedFilterLists = JSON.parse(JSON.stringify(this.selectedFilterLists))
        this.openTab()
    }

    private async setCampaignLists () {
        this.loading = true
        const data = [
            ...this.parseSelectedLists(this.tempSelectedLists),
            ...this.parseSelectedLists(this.tempSelectedFilterLists, true),
        ]

        if (this.mappedTags.length > 0 && this.selectedLists.length > 0) {
            let openConfirm = false
            if (this.tempSelectedLists.length > 1) openConfirm = true
            if (this.tempSelectedLists.length === 1 && !this.selectedLists.find(el => el.id === this.tempSelectedLists[0].id)) {
                openConfirm = true
            }
            if (openConfirm) {
                try {
                    await this.$refs.confirmDeleteMappedTags.openConfirm()
                    this.loading = false
                } catch (e) {
                    this.loading = false
                    return
                }
                try {
                    await setEmailTags(this.campaign.message.data.id, [])
                    this.$emit('mapped-tag-saved')
                } catch (e) {
                    console.log(e)
                }
            }
        }

        try {
            await setCampaignLists(this.campaignId, {
                data,
            })
            await this.getCampaignLists()
            await this.calculateTotalContacts()
            this.$emit('open-tab', '')
            this.$emit('target-saved')
            this.$root.$vsToast({
                timeout: 3000,
                heading: this.$t('campaigns.editCampaign.sections.target.listsSaved'),
                aspect: VsToastAspectEnum.success,
            })
        } catch (e) {
            let message = ''
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400) {
                    let msg: any = Object.values(get(e, 'response.data.message', {}))
                    if (msg && msg.length > 0) {
                        msg = msg[0][0]
                        if (msg.includes('recipient_id must be unique') || msg.includes('segment_id must be unique')) {
                            message = this.$t('campaigns.editCampaign.sections.target.errors.unique').toString()
                        } else if (msg.includes('recipient_id is invalid')) {
                            const index = parseInt(msg.replace('The data.', '').replace('.recipient_id is invalid', ''))
                            message = `${data[index].name} ${this.$t('campaigns.editCampaign.sections.target.errors.invalidRecipientOrSegment')}`
                        } else if (msg.includes('segment_id is invalid')) {
                            message = `${data[parseInt(msg.replace('The data.', '').replace('.segment_id is invalid', ''))].name} ${this.$t('campaigns.editCampaign.sections.target.errors.invalidRecipientOrSegment')}`
                        }
                    }
                }
                if (e.response?.status === 403) {
                    message = 'Non hai il permesso di inviare su più di una lista'
                }
            }

            this.$root.$vsToast({
                timeout: message ? 5000 : 3000,
                heading: this.$t('campaigns.editCampaign.sections.target.errors.default'),
                message,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    // parse app data to api data
    private parseSelectedLists (list: any, exclude?: boolean) {
        const sel = []
        for (const selList of list) {
            if (selList.profilations.length > 0) {
                sel.push(
                    ...selList.profilations.map((prof: any) => {
                        return {
                            recipient_id: selList.id,
                            // name: selList.name,
                            segment_id: prof.id,
                            exclude,
                        }
                    }),
                )
            }

            if (selList.tags.length > 0) {
                sel.push(
                    ...selList.tags.map((prof: any) => {
                        return {
                            recipient_id: selList.id,
                            // name: selList.name,
                            tag_id: prof.id,
                            exclude,
                        }
                    }),
                )
            }

            if (selList.tags.length === 0 && selList.profilations.length === 0) {
                sel.push({
                    recipient_id: selList.id,
                    // name: selList.name,
                    segment_id: 0,
                    tag_id: 0,
                    exclude,
                })
            }
        }
        return sel
    }

    private saveTarget () {
        this.setCampaignLists()
        // this.showPianificationError = false
    }

    private async openConfirmDraft () {
        this.$emit('open-confirm-draft')
    }

    private sharedListHasErrors (list: any) {
        if (list.profilations.length > 0) return false
        return !this.sharedLists.find((el: any) => el.recipient_id === list.id && el.segment_id === 0)
    }

    private listIsDuplicated (list: any) {
        return !!this.tempSelectedLists.find((el) => {
            return el.id === list.id && list.tags.length === 0 && list.profilations.length === 0
        })
    }

    private filterIsDuplicated (list: any, filter: any, filterType: 'tags' | 'profilations') {
        const selList = this.tempSelectedLists.find((el) => el.id === list.id)
        if (!selList) return false
        return !!selList[filterType].find((el: any) => el.id === filter.id)
    }

    private openSelectListModal () {
        this.addToExclusion = false
        this.$refs.vsSelectListModal.openModal(this.tempSelectedLists)
    }

    private openSelectFiltersModal (tmpList: any) {
        this.addToExclusion = false
        if (tmpList.shared) {
            this.$refs.vsSelectSharedListFiltersModal.openModal(tmpList.id, tmpList.profilations, tmpList.tags)
        } else {
            this.$refs.vsSelectListFiltersModal.openModal(tmpList.id, tmpList.profilations, tmpList.tags)
        }
    }

    private openSelectExclusionListModal () {
        this.addToExclusion = true
        this.$refs.vsSelectListModal.openModal([
            ...this.tempSelectedFilterLists,
            // ...this.tempSelectedLists.filter(el => el.tags.length === 0 && el.profilations.length === 0),
        ])
    }

    private openSelectExclusionFiltersModal (tmpList: any) {
        this.addToExclusion = true
        const allSelectedProfilations: any[] = []
        const allSelectedTags: any[] = []
        this.tempSelectedLists.forEach(el => {
            allSelectedProfilations.push(...el.profilations)
            allSelectedTags.push(...el.tags)
        })
        if (tmpList.shared) {
            this.$refs.vsSelectSharedListFiltersModal.openModal(
                tmpList.id,
                [
                    ...tmpList.profilations,
                    // ...allSelectedProfilations,
                ],
                [
                    ...tmpList.tags,
                    // ...allSelectedTags,
                ],
            )
        } else {
            this.$refs.vsSelectListFiltersModal.openModal(
                tmpList.id,
                [
                    ...tmpList.profilations,
                    // ...allSelectedProfilations,
                ],
                [
                    ...tmpList.tags,
                    // ...allSelectedTags,
                ],
            )
        }
    }
}
