


























































import { Component, Vue } from 'vue-property-decorator'
import axios from 'axios'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'
import VsProfilationsTab from '@/modules/campaigns/components/VsSelectListFiltersModal/profilationsTab.vue'
import VsTagsTab from '@/modules/campaigns/components/VsSelectListFiltersModal/tagsTab.vue'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsSelectListFiltersModal',
    components: {
        VsActionCard,
        VsTabs,
        VsTagsTab,
        VsProfilationsTab,
    },
})
export default class extends Vue {
    private show = false
    private loading = false
    private cancelTokenSource = axios.CancelToken.source()
    private total = 0
    private listFilters: any[] = []
    private selectedTags: any[] = []
    private selectedProfilations: any[] = []
    private listId = 0
    private tagsToRemove: any[] = []
    private profilationsToRemove: any[] = []
    private filterType = 'profilations'

    get totalSelected () {
        return this.selectedProfilations.length + this.selectedTags.length
    }

    get user () {
        return UserModule.user
    }

    get tabs () {
        const tabs = [
            {
                label: 'Profilazioni',
                id: 'profilations',
            },
            {
                label: 'Etichette',
                id: 'tags',
                clickDisabled: this.$root.$vsOpenBlockedModal,
            },
        ]

        return tabs
    }

    public openModal (listId: any, profilationsToRemove: any[], tagsToRemove: any[]) {
        this.show = true
        this.listId = listId
        this.listFilters = []
        this.selectedProfilations = []
        this.selectedTags = []
        this.tagsToRemove = tagsToRemove
        this.profilationsToRemove = profilationsToRemove
    }

    public closeModal () {
        this.show = false
    }

    addListFiltersToCampaign () {
        this.$emit('filters-selected', {
            profilations: this.selectedProfilations,
            tags: this.selectedTags,
            listId: this.listId,
        })
        this.closeModal()
    }
}
