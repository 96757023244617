
























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'
import VsProfilationsTab from '@/modules/campaigns/components/VsSelectListFiltersModal/profilationsTab.vue'
import VsTagsTab from '@/modules/campaigns/components/VsSelectListFiltersModal/tagsTab.vue'

@Component({
    name: 'VsSelectListFiltersModal',
    components: {
        VsActionCard,
        VsTabs,
        VsTagsTab,
        VsProfilationsTab,
    },
})
export default class extends Vue {
    @Prop({ default: () => [], required: false }) sharedLists!: any[]
    private show = false
    private total = 0
    private selectedTags: any[] = []
    private selectedProfilations: any[] = []
    private listId = 0
    private tagsToRemove: any[] = []
    private profilationsToRemove: any[] = []
    private loading = false

    private filters = {
        search: '',
    }

    private pagination: any = {
        orderBy: null,
    }

    get profilationsOrderBy () {
        return [
            {
                label: this.$t('general.orderBy.createdAtDesc'),
                value: 'created_at|desc',
            },
            {
                label: this.$t('general.orderBy.createdAtAsc'),
                value: 'created_at|asc',
            },
        ]
    }

    get listFilters () {
        return this.sharedLists.filter((el: any) => {
            return el.recipient && el.recipient.id === this.listId && el.segment
        }).map((el: any) => {
            return {
                ...el.segment,
                shared: true,
            }
        })
    }

    get totalSelected () {
        return this.selectedProfilations.length + this.selectedTags.length
    }

    get tabs () {
        const tabs = [
            {
                label: 'Profilazioni',
                id: 'profilations',
            },
            {
                label: 'Etichette',
                id: 'tags',
            },
        ]

        return tabs
    }

    public openModal (listId: any, profilationsToRemove: any[], tagsToRemove: any[]) {
        this.show = true
        this.listId = listId
        this.selectedProfilations = []
        this.selectedTags = []
        this.tagsToRemove = tagsToRemove
        this.profilationsToRemove = profilationsToRemove
    }

    public closeModal () {
        this.show = false
    }

    private isChecked (filter: any) {
        return !!this.selectedProfilations.find(el => el.id === filter.id)
    }

    private toggleSelection (filter: any) {
        const index = this.selectedProfilations.findIndex(el => el.id === filter.id)
        if (index === -1) {
            this.selectedProfilations.push(filter)
        } else {
            this.selectedProfilations.splice(index, 1)
        }
    }

    addListFiltersToCampaign () {
        this.$emit('filters-selected', {
            profilations: this.selectedProfilations,
            tags: this.selectedTags,
            listId: this.listId,
        })
        this.closeModal()
    }

    private exclude (filter: any) {
        return !!this.profilationsToRemove.find(el => el.id === filter.id)
    }
}
