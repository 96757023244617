

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getListSegments } from '@/api/consoleApi/recipients'
import axios from 'axios'
import VsActionCard from '@/components/VsActionCard/Index.vue'
import VsTabs from '@/components/VsTabs/Index.vue'

@Component({
    name: 'VsSelectListFiltersModal',
    components: {
        VsActionCard,
        VsTabs,
    },
})
export default class extends Vue {
    @Prop({ default: () => [], required: false }) value!: any[]
    @Prop({ default: 0, required: false }) listId!: any
    @Prop({ default: () => [], required: false }) toExclude!: any[]
    private loading = false
    private cancelTokenSource = axios.CancelToken.source()
    private total = 0
    private listFilters: any[] = []

    get profilationsOrderBy () {
        return [
            {
                label: this.$t('general.orderBy.createdAtDesc'),
                value: 'created_at|desc',
            },
            {
                label: this.$t('general.orderBy.createdAtAsc'),
                value: 'created_at|asc',
            },
        ]
    }

    private filters = {
        search: '',
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    @Watch('filters', { deep: true, immediate: false })
    async clearPaginationAndGetData () {
        this.pagination.page = 1
        this.getListFilters()
    }

    @Watch('pagination', { deep: true, immediate: true })
    async getListFilters () {
        try {
            const response = await getListSegments(
                this.listId,
                this.buildParams(),
            )
            this.listFilters = response.data.data
            this.total = response.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.listFilters = []
            this.total = 0
        }
    }

    private buildParams () {
        const params: any = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
            searchJoin: 'and',
            search: this.buildSearch(),
            searchFields: this.buildSearchFields(),
        }

        if (this.pagination.orderBy) {
            const order = this.pagination.orderBy.split('|')
            params.orderBy = order[0]
            params.sortedBy = order[1]
        }

        return params
    }

    private buildSearch () {
        const search = [
            this.filters.search.trim() !== '' ? `name:${this.filters.search}` : '',
        ].filter((el) => el !== '')

        return search.join(';')
    }

    private buildSearchFields () {
        const searchFields = [
            this.filters.search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    private isChecked (filter: any) {
        return !!this.value.find(el => el.id === filter.id)
    }

    private toggleSelection (filter: any) {
        const index = this.value.findIndex(el => el.id === filter.id)
        const newValue = [
            ...this.value,
        ]
        if (index === -1) {
            newValue.push(filter)
        } else {
            newValue.splice(index, 1)
        }
        this.$emit('input', newValue)
    }

    private exclude (filter: any) {
        return !!this.toExclude.find(el => el.id === filter.id)
    }
}
